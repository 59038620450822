
import {
  Component,
  Vue
} from 'vue-property-decorator'
import {
  ToastModule
} from '@/store/modules/ToastModule'
import {
  AuthModule
} from '@/store/modules/AuthModule'
import TransferService from '../services/transfer-service'
import UomJobs from '@/data/uom-jobs.json'
import Status from '@/components/Status/index.vue'
  @Component({
    name: 'Transfer',
    components: {
      Status
    }
  })
export default class Transfer extends Vue {
    protected types: any = ['Requested', 'Transfered']
    protected type: any = null

    protected uoms = UomJobs
    private bargeData: any = {}
    protected barge: any = {
      id: null,
      quantity: null,
      unit_of_measurement: null,
      from_barge_name: null,
      from_barge_id: null,
      to_barge_id: null,
      modified_user: null
    }

    protected barges: any = []
    protected jobs: any = [{
      id: null,
      is_active: false,
      reference_number: null,
      collected_quantity: null,
      quantity: 0
    }]

    protected lines: any = []
    protected fields = [{
      key: '#',
      label: '#',
      thStyle: {
        width: '5%'
      },
      class: 'text-right'
    },
    {
      key: 'from_barge_name',
      label: 'From Barge',
      thStyle: {
        width: '15%'
      }
    },
    {
      key: 'jobs',
      label: 'Jobs',
      thStyle: {
        width: '10%'
      }
    },
    {
      key: 'quantity',
      label: 'Quantity',
      thStyle: {
        width: '10%'
      }
    },
    {
      key: 'to_barge_name',
      label: 'To Barge',
      thStyle: {
        width: '15%'
      }
    },
    {
      key: 'status',
      label: 'Status',
      thStyle: {
        width: '10%'
      },
      class: 'text-center'
    },
    {
      key: 'action',
      label: '',
      thStyle: {
        width: '10%'
      },
      class: 'text-center'
    }
    ]

    protected filteredBarges: any = []
    public transfer: any = {
      from_barge_id: AuthModule.party.id,
      from_barge_name: AuthModule.user.fullname,
      to_barge: {},
      // quantity: null,
      unit_of_measurement: null,
      created_user: AuthModule.user.fullname,
      modified_user: AuthModule.user.fullname
    }

    created () {
      this.boot()
    }

    protected boot () {
      // this.getBargeData()
      // this.getBarges()
      // this.populateJobNumbers()
    }

    get transferQty () {
      return this.jobs.reduce((total, job) => {
        return total + parseInt(job.quantity)
      }, 0)
    }

    public openTransferModal (item: any) {
      (this.$refs.bargeTransfer as any).show()
    }

    public async getBargeData () {
      const response = await TransferService.getBargeData(AuthModule.party.id)
      this.bargeData = response.data
    }

    protected async getCollectedQty (jobId, index) {
      const response = await TransferService.getCollectedQty(jobId)
      this.jobs[index].collected_quantity = response.data
    }

    protected async getBarges () {
      const response = await TransferService.getBarges()
      response.data.forEach((barge) => {
        this.barges.push({
          value: {
            id: barge.id,
            name: barge.name
          },
          text: barge.name
        })
      })
      this.populateToBarge()
    }

    protected populateToBarge () {
      let barges: any = []
      barges = this.barges.slice()
      barges.forEach((element, index) => {
        if (element.value.id === AuthModule.party.id) barges.splice(index, 1)
      })
      this.filteredBarges = barges
    }

    protected saveBargeTransfer () {
      if (parseInt(this.transfer.quantity) > parseInt(this.bargeData.remain_on_board)) {
        alert('Quantity exceeds barge ROB, Please select a proper quantity')
        return false
      }
      const transfer = this.transfer
      const jobs: any = []

      transfer.to_barge_id = this.transfer.to_barge.id
      transfer.to_barge_name = this.transfer.to_barge.name
      transfer.quantity = this.transferQty

      this.jobs.forEach((job) => {
        if (job.is_active === true) {
          jobs.push({
            job_id: job.id,
            reference_number: job.reference_number,
            quantity: job.quantity,
            created_user: AuthModule.user.fullname,
            modified_user: AuthModule.user.fullname
          })
        }
      })

      TransferService.saveBargeTransfer(transfer, jobs).then((response) => {
        ToastModule.message(response.data.message)
        this.transfer = {
          to_barge: {},
          quantity: null,
          unit_of_measurement: null
        }
      }).catch(error => {
        console.log(error)
      })
    }
}

