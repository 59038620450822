import APIService from '@/services/api-service'
const resource = '/barge-transfer'

class TransferService extends APIService {
  public constructor () {
    super()
    this.mountAuthorizationToken()
  }

  public create (header: any, lines: any) {
    return this.connector.post(`${resource}/create`, { header: header, lines: lines })
  }

  public getBargeData (bargeId) {
    return this.connector.get(`${resource}/${bargeId}/barge-data`)
  }

  public getBarges () {
    return this.connector.get(`${resource}/barges`)
  }

  public saveBargeTransfer (transfer: any, jobs: any) {
    return this.connector.post(`${resource}/save`, { transfer: transfer, jobs: jobs })
  }

  public getBargeTransfer (bargeId) {
    return this.connector.get(`${resource}/${bargeId}/all`)
  }

  public acceptBargeTransfer (data: any) {
    return this.connector.put(`${resource}/accept`, data)
  }

  public authorizeBargeTransfer (data: any) {
    return this.connector.put(`${resource}/authorize`, data)
  }

  public getAllBargeTransfers () {
    return this.connector.get(`${resource}/all`)
  }

  public rejectBargeTransfer (data: any) {
    return this.connector.put(`${resource}/reject`, data)
  }

  public getScheduledJobs () {
    return this.connector.get(`${resource}/scheduled-jobs`)
  }

  public getCollectedQty (jobId: any) {
    return this.connector.get(`${resource}/collected-qty/${jobId}`)
  }
}

export default new TransferService()
